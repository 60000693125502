<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.sort === false}"
                        class="form-label"
                        for="sort"
                    >{{ $t('partners::lead_sources.columns.sort').ucFirst() }}</label
                    >
                    <input
                        id="sort"
                        v-model="model.sort"
                        :class="{'border-danger': states.sort === false}"
                        :placeholder="$t('partners::lead_sources.placeholders.sort').ucFirst()"
                        class="form-control"
                        type="number"
                    />
                    <div :class="{'d-block': states.sort === false}" class="invalid-feedback">{{ errors.sort }}</div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.code === false}"
                        class="form-label"
                        for="code"
                    >{{ $t('partners::lead_sources.columns.code').ucFirst() }}</label
                    >
                    <input
                        id="code"
                        v-model="model.code"
                        :class="{'border-danger': states.code === false}"
                        :placeholder="$t('partners::lead_sources.placeholders.code').ucFirst()"
                        class="form-control"
                        type="text"
                    />
                    <div :class="{'d-block': states.code === false}" class="invalid-feedback">{{ errors.code }}</div>
                </div>
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.name === false}"
                        class="form-label"
                        for="name"
                    >{{ $t('partners::lead_sources.columns.name').ucFirst() }} <span class="text-primary">*</span></label
                    >
                    <input
                        id="name"
                        v-model="model.name"
                        :class="{'border-danger': states.name === false}"
                        :placeholder="$t('partners::lead_sources.placeholders.name').ucFirst()"
                        class="form-control"
                        name="name"
                        type="text"
                    />
                    <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{ errors.name }}</div>
                </div>
                <div class="col-lg-5 mb-4">
                    <label
                        :class="{'text-danger': states.token === false}"
                        class="form-label"
                        for="token"
                    >{{ $t('partners::lead_sources.columns.token').ucFirst() }}</label
                    >
                    <div class="input-group">
                        <input
                            id="token"
                            v-model="model.token"
                            :class="{'border-danger': states.token === false}"
                            :placeholder="$t('partners::lead_sources.placeholders.token').ucFirst()"
                            class="form-control"
                            name="token"
                            type="text"
                            readonly
                        />
                        <b-button variant="secondary" @click="copyToClipboard" :title="$t('base.copy_to_clipboard').ucFirst()">
                            <i class="fas fa-copy"></i>
                        </b-button>
                        <b-button variant="danger" v-if="model.id" @click.prevent="generate" :title="$t('base.generate_item', {item: $t('partners::lead_sources.columns.token')}).ucFirst()">
                            <i class="fas fa-magic"></i>
                        </b-button>
                    </div>
                    <div :class="{'d-block': states.token === false}" class="invalid-feedback">{{ errors.token }}</div>
                </div>
                <div class="col-lg-12 mb-4">
                    <label
                        :class="{'text-danger': states.abilities === false}"
                        class="form-label"
                        for="name"
                    >{{ $t('partners::lead_sources.columns.abilities').ucFirst() }}</label
                    >
                    <select
                        id="partner"
                        v-model="ability"
                        class="form-select"
                        name="partner"
                        @change="setAbility"
                    >
                        <option :value="null">
                            {{ $t('base.please_select').ucFirst() }}
                        </option>
                        <option v-for="(item, key) in roles" :key="key" :value="item.key">{{
                                item?.category_name?.ucFirst() + ': ' + item.translated_authority
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.abilities === false}" class="invalid-feedback">{{ errors.abilities }}</div>
                    <div class="mt-4">
                        <span class="badge bg-danger me-2" v-for="(ability, index) in abilities" :key="index">{{ ability?.category_name?.ucFirst() }} {{ ability?.translated_authority }} <i class="fas fa-trash cursor-pointer" @click="removeAbility(index)"></i></span>
                    </div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<script>
import {mapGetters} from "vuex";
import Swal from "sweetalert2";

export default {
    name: "LeadSourceForm",

    emits: ['saved'],

    props: {
        leadSource: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'leadSource/loading',
            'states': 'leadSource/states',
            'errors': 'leadSource/errors',
            'roles': 'role/all'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },

        abilities: function () {
            return this.model.abilities?.map(item => {
                return this.roles.find(role => role.key === item)
            }) || []
        },
    },

    data: function () {
        return {
            model: JSON.parse(JSON.stringify(this.leadSource)),
            ability: null
        }
    },

    methods: {
        save: function () {
            const method = this.model.id ? 'update' : 'create'

            return this.$store.dispatch(`leadSource/${method}`, this.model).then(saved => {
                this.model = JSON.parse(JSON.stringify(saved))
                this.$emit('saved')
                if (method === 'create') {
                    this.$router.push(this.$t('routes.' + '/master-data/lead-sources/:id(\\d+)', {id: this.model.id}).rmValidation())
                }
            })
        },

        generate: function () {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.confirm_title'),
                text: this.$t('base.confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.generate').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch(`leadSource/generateToken`, this.model.id)
                }
            });
        },

        copyToClipboard: function () {
            navigator.clipboard.writeText(this.model.token);
        },

        setAbility: function () {
            if (!this.model.abilities) {
                this.model.abilities = []
            }

            this.model.abilities.push(this.ability)

            this.ability = null;
        },

        removeAbility: function (index) {
            this.model.abilities.splice(index, 1)
        },
    },

    watch: {
        leadSource: function () {
            this.model = Object.assign({abilities: ['partners.create']}, this.model, JSON.parse(JSON.stringify(this.leadSource)))
        },
    },

    created() {
        // console.log(this.leadSource)
    }
}
</script>

<style scoped>

</style>
