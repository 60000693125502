<script>
import {mapGetters} from "vuex";

export default {
    name: "lead-source-commodity-form",

    emits: ['add', 'update'],

    props: {
        item: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...mapGetters({
            loading: 'leadSourceCommodity/loading',
            states: 'leadSourceCommodity/states',
            errors: 'leadSourceCommodity/errors',
            billables: 'billable/all',
            products: 'billable/products',
            services: 'billable/services',
            users: 'user/all'
        }),

        billableModelTypes: function () {
            return [
                {
                    value: 'Appon\\Projects\\Models\\Product',
                    store: 'product',
                    label: this.$tc('projects::products.product', 2)
                },
                {
                    value: 'Appon\\Projects\\Models\\Service',
                    store: 'service',
                    label: this.$tc('projects::services.service', 2)
                }
            ]
        },

        btnDisabled: function () {
            return !this.billable
        },

        btnText: function () {
            return this.$t('base.' + (!this.model?.id ? 'add' : 'save')).ucFirst()
        },
    },

    data: function () {
        return {
            billable: null,
            model: {},
            extra: {},
        }
    },

    methods: {
        setFile: function (event, prop = 'file_file') {
            this.model[prop] = this.extra[prop] = event.target.files[0]
        },

        setModel: function (model = {}) {
            const base = {}

            if (this.billable) {
                base.commodity_id = this.billable?.id;
                base.commodity_type = this.billable?.type;
            }

            this.model = Object.assign(base, this.extra, JSON.parse(JSON.stringify(model)));
        },

        setDetails: function () {
            this.setModel(Object.assign(this.item, {commodity_id: this.billable?.id, commodity_type: this.billable?.type}))
        },

        setBillable: function (item) {
            if (item?.commodity_type && item?.commodity_id) {
                this.billable = this.billables.find(billable => billable.type === item.commodity_type && billable.id === item.commodity_id)
            }
        },

        save: function () {
            if (this.model.id) {
                this.$emit('update', this.model);
            } else {
                this.$emit('add', this.model);
            }
        },
    },

    watch: {
        item: {
            immediate: true,
            deep: true,
            handler: function(value) {
                this.setModel(value);
                this.setBillable(value)
            },
        },

        extra: {
            immediate: true,
            deep: true,
            handler: function() {
                this.setModel(this.model);
            },
        },
    },

    unmounted() {
        this.$store.dispatch(`leadSourceCommodity/clearItem`);
    }
}
</script>

<template>
    <form>
        <b-overlay :show="loading" rounded>
            <div class="row">
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.commodity_id === false}"
                        class="form-label"
                        for="billable_id-input"
                    >{{ $t('partners::lead_source_commodities.columns.commodity_id').ucFirst() }}</label
                    >
                    <select
                        id="commodity_id-input"
                        v-model="billable"
                        :class="{'border-danger': states.commodity_id === false}"
                        class="form-select"
                        name="commodity_id"
                        @change="setDetails"
                    >
                        <option :value="billable === undefined ? undefined : null">
                            {{ $t('base.please_select').ucFirst() }}
                        </option>
                        <optgroup :label="$tc('projects::products.product', 2).ucFirst()">
                            <option v-for="(item, index) in products" :key="index" :value="item">
                                {{ item.translated }}
                            </option>
                        </optgroup>
                        <optgroup :label="$tc('projects::services.service', 2).ucFirst()">
                            <option v-for="(item, index) in services" :key="index" :value="item">
                                {{ item.translated }}
                            </option>
                        </optgroup>
                    </select>
                    <div :class="{'d-block': states.commodity_id === false}" class="invalid-feedback">{{ errors.commodity_id }}</div>
                </div>

                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.user_id === false}"
                        class="form-label"
                        for="user_id-input"
                    >{{ $t('partners::lead_source_commodities.columns.user_id').ucFirst() }}</label
                    >
                    <select
                        id="user_id-input"
                        v-model="model.user_id"
                        :class="{'border-danger': states.user_id === false}"
                        class="form-select"
                        name="user_id"
                    >
                        <option :value="billable === undefined ? undefined : null">
                            {{ $t('partners::lead_source_commodities.placeholders.user_id').ucFirst() }}
                        </option>
                        <option v-for="(item, index) in users" :key="index" :value="item.id">
                            {{ item.name }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.user_id === false}" class="invalid-feedback">{{ errors.user_id }}</div>
                </div>

                <slot name="inputs" v-bind:states="states" v-bind:errors="errors" v-bind:extra="extra" v-bind:setFile="setFile"></slot>
            </div>
            <div class="row">
                <div class="col-12 mb-4 text-center">
                    <b-button variant="success" :disabled="btnDisabled" @click="save">{{ btnText }}</b-button>
                    <slot name="buttons"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
