<script>
import {mapGetters} from "vuex";
import SimpleTable from "./simple-table.vue";
import TransitionToggle from "../transitions/toggle.vue";
import Swal from "sweetalert2";
import LeadSourceCommodityForm from "../forms/lead-source-commodity-form.vue";

export default {
    name: "lead-source-commodity-manager",

    components: {LeadSourceCommodityForm, TransitionToggle, SimpleTable},

    props: {
        leadSourceId: {
            type: Number,
            required: true
        },

        extraFields: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },

    computed: {
        ...mapGetters({
            items: 'leadSourceCommodity/all'
        }),

        fields: function () {
            return [
                {
                    key: 'commodity',
                    label: this.$t('partners::lead_source_commodities.relationships.commodity').ucFirst(),
                    formatter: (value) => value?.translation_key ? this.$t(value.translation_key) : value?.name
                },
                {
                    key: 'user',
                    label: this.$t('partners::lead_source_commodities.relationships.user').ucFirst(),
                    formatter: (value) => value?.name,
                },
                {
                    key: 'url',
                    label: this.$t('partners::lead_source_commodities.columns.url').ucFirst()
                },
                ...this.extraFields,
                {
                    key: "actions",
                    label: this.$t('base.actions').ucFirst(),
                    tdClass: 'text-end'
                }
            ];
        },
    },

    data: function () {
        return {
            showForm: false,
            item: {
                lead_source_id: this.leadSourceId
            },
        }
    },

    methods: {
        editTableItem: function (item, callback) {
            this.$store.dispatch('leadSourceCommodity/setItem', item).then(() => {
                callback(item)
            })
        },

        removeTableItem: function (item) {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.delete_confirm_title'),
                text: this.$t('base.delete_confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.delete').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch('leadSourceCommodity/destroy', item.id)
                }
            });
        },

        updateTableItem: function (item, callback) {
            item.query = {with: ['commodity', 'user', 'user.image']}
            this.$store.dispatch('leadSourceCommodity/update', item).then(() => {
                this.$store.dispatch('leadSourceCommodity/clearItem')
                callback()
            })
        },

        add: function (item) {
            this.$store.dispatch('leadSourceCommodity/create', item).then(() => {
                this.$store.dispatch(`${this.store}/clearItem`)
                this.showForm = false
            })
        },

        clearItem: function () {
            this.item = {lead_source_id: this.leadSourceId}
            this.$store.dispatch('leadSourceCommodity/clearItem')
        },

        copyToClipboard: function (value) {
            navigator.clipboard.writeText(value);
        },
    },

    created() {
        this.$store.dispatch('leadSourceCommodity/all', {lead_source_id: this.leadSourceId, with: ['commodity', 'user', 'user.image']})
        this.$store.dispatch('user/all')
        this.$store.dispatch('billable/all')
    }
}
</script>

<template>
    <div class="lead-source-commodities-manager">
        <simple-table :items="items" :fields="fields">
            <template #actions="{item, edit}">
                <span class="text-warning cursor-pointer p-2" :title="$t('base.edit').ucFirst()" @click="editTableItem(item, edit)">
                    <i class="fas fa-edit"></i>
                </span>
                <span class="text-danger cursor-pointer p-2" :title="$t('base.delete').ucFirst()" @click="removeTableItem(item)">
                    <i class="fas fa-trash-alt"></i>
                </span>
            </template>
            <template #default="{close, item}">
                <lead-source-commodity-form :item="item" @update="updated => updateTableItem(updated, close)">
                    <template #buttons>
                        <b-button class="ms-2" variant="outline-secondary" @click="close">{{ $t('base.cancel').ucFirst() }}</b-button>
                    </template>
                </lead-source-commodity-form>
            </template>
            <template #user="{ value }">
                <div class="avatar-group-item">
                    <a v-if="value?.image && value?.image?.path" href="javascript: void(0);" class="d-inline-block">
                        <img :src="value.image.path"
                             :alt="value.name"
                             :title="value.name"
                             class="rounded-circle avatar-sm">
                    </a>
                    <div v-else class="avatar-sm cursor-pointer">
                        <span :title="value?.name" class="avatar-title rounded-circle bg-primary text-white font-size-16 user-select-none"> {{ value?.name?.firstLetters() }} </span>
                    </div>
                </div>
            </template>
            <template #url="{value}">
                <span class="cursor-pointer hover" @click="copyToClipboard(value)" :title="$t('base.copy_to_clipboard').ucFirst()">{{ value }}</span>
            </template>
        </simple-table>
        <transition-toggle>
            <lead-source-commodity-form v-if="showForm" class="border rounded p-3 bg-white" :item="item" @add="add">
                <template #buttons>
                    <b-button class="ms-2" variant="outline-secondary" @click="showForm = !showForm">{{ $t('base.cancel').ucFirst() }}</b-button>
                </template>
            </lead-source-commodity-form>
        </transition-toggle>
        <transition-toggle>
            <div class="col-12 mb-2 text-center" v-if="!showForm">
                <b-button variant="success" @click="showForm = !showForm">{{ $t('base.add_new').ucFirst() }}</b-button>
            </div>
        </transition-toggle>
        <div class="row">
            <div class="col-12">
                <p></p>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
