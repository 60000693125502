<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ title }}</h4>
                </div>
                <div class="card-body">
                    <lead-source-form :lead-source="leadSource">
                        <template #buttons="{save}">
                            <callback-button
                                class="ms-2"
                                :method="save"
                                :callback="back"
                            ></callback-button>
                        </template>
                    </lead-source-form>
                </div>
            </div>
        </div>
        <div class="col-lg-12" v-if="leadSource.id">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $tc('partners::lead_source_commodities.lead_source_commodity', 2).ucFirst() }}</h4>
                </div>
                <div class="card-body bg-light">
                    <lead-source-commodity-manager :lead-source-id="leadSource.id"></lead-source-commodity-manager>
                </div>
            </div>
        </div>
    </div>
    <!-- end row -->
</template>

<script>

import {mapGetters} from "vuex";
import LeadSourceForm from "../../../components/forms/lead-source-form.vue";
import CallbackButton from "../../../components/elements/callback-button.vue";
import LeadSourceCommodityManager from "../../../components/elements/lead-source-commodity-manager.vue";

export default {
    components: {LeadSourceCommodityManager, CallbackButton, LeadSourceForm},

    computed: {
        ...mapGetters({
            leadSource: 'leadSource/item'
        }),

        title: function () {
            return this.$route.params.id ? this.$t('partners::lead_sources.lead_source', 2).ucFirst() + ' / ' + this.leadSource.name : this.$t('base.create_new_item', {item: this.$tc('partners::lead_sources.lead_source', 1)}).ucFirst()
        },

        link: function () {
            return {path: this.$t('routes.' + '/master-data/lead-sources')}
        }
    },

    methods: {
        back: function () {
            this.$router.push(this.link)
        },
    },

    watch: {
        leadSource: {
            immediate: true,
            deep: true,
            handler: function () {
                this.$store.dispatch('app/title', {key: this.title})
            },
        }
    },

    mounted() {
        if (this.$route.params.id) {
            this.$store.dispatch('leadSource/show', {id: this.$route.params.id})
        }
        this.$store.dispatch('role/all', {with: ['category']})
    },

    unmounted() {
        this.$store.dispatch('leadSource/clearItem')
        this.$store.dispatch('app/title', '')
    }

}
</script>

<style scoped>

</style>
